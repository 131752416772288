import { useStaticQuery, graphql } from "gatsby"
import { Link } from "@StarberryUtils";
import React, { useEffect, useState, useRef } from "react"
import { useLocation } from "@reach/router"
import { Container, Row, Col, Button, Overlay, Tooltip } from "react-bootstrap"
import AgentImg from "../../../images/member.jpg"
import image from "../../../images/property-image.jpg"
import Form from "../../forms/book-a-viewing-form"
import SendForm from "../../forms/send-friend-form"
import ValuationFormPage from "../../forms/valuation-form";
import ModalTiny from "react-bootstrap/Modal"
import { CopyToClipboard } from "react-copy-to-clipboard";
import _ from "lodash"
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";

import {
  EmailIcon,
  FacebookIcon,
  LinkedinIcon,
  TwitterIcon,
  WhatsappIcon
} from "react-share";
import "./ProductInfo.scss"
import CurrConvertLayout from "../../currency-converter/CurrConvertLayout";
import { CurrConvertPrice } from "../../currency-converter/CurrConvertPrice";
import ImageTransform from "../../common/ggfx-client/module/components/image-transform";
import { CallIconwhite, EmailIconWhite, WhatsAppIconWhite, ShareIcon, CloseIcon } from "../../Icons/Icons";

// styles



const ProductInfo = (props) => {

  const data = useStaticQuery(graphql`
  query {
    glstrapi {
      peoples {
        Designation
        Email
        Name
        Phone
        imagetransforms
        Tile_Image {
          url
        }
        URL
        id
        Neg_Id
        reapit_code
        certified_people{
          Designation
          Email
          Name
          Phone
          Tile_Image {
            url
          }
          URL
        }
      }
    }
  }
`);

  // let PageURL = data.glstrapi.peoples.filter(item => item.id === id).pop();
  let peoples = data.glstrapi.peoples;
  // Share icons
  const location = useLocation();

  const shareurl = typeof window !== 'undefined' ? window.location.href : ''
  const [Shareicons, setShareicons] = React.useState(false);
  const [negotiator, setNegotiator] = React.useState(null);
  const [otherPeople, setOtherpeople] = React.useState(false);
  const [ShareiconsMobile, setShareiconsMobile] = React.useState(false);
  const [modalAskformOpen, setAskformOpen] = React.useState(false);
  const [propertyImg, setPropertyImg] = React.useState(process.env.GATSBY_SITE_URL + '/images/property-image.jpg');

  const openShareicons = () => {
    setShareicons(true);
  }

  const closeShareicons = () => {
    setShareicons(false);
  }
  const openShareiconsMobile = () => {
    setShareiconsMobile(true);
  }

  const closeShareiconsMobile = () => {
    setShareiconsMobile(false);
  }

  const openAskformModal = () => {
    setAskformOpen(true);
  }
  const closeAskformModal = () => {
    setAskformOpen(false);
  }

  const trackerShare = (event) => {
    var shareURL = typeof window !== 'undefined' ? window.location.href : ''
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'Share - social icons',
      'formType': event + ' - ' + shareURL,
      'formId': 'Share',
      'formName': 'Share',
      'formLabel': 'Share'
    });
  }
  // Share icons

  const handleBack = () => window.history.back();
  const [copied, setCopied] = useState(false);
  const target = useRef(null);

  const [modalBookViewformOpen, setBookViewingformOpen] = React.useState(false);
  const openBookViewformModal = () => {
    setBookViewingformOpen(true);
  }
  const closeBookViewformModal = () => {
    setBookViewingformOpen(false);
  }

  const [modalSendfrndformOpen, setSendfrndformOpen] = React.useState(false);
  const openSendfrndformModal = () => {
    setSendfrndformOpen(true);
  }
  const closeSendfrndformModal = () => {
    setSendfrndformOpen(false);
  }

  const [modalValuationformOpen, setValuationformOpen] = React.useState(false);
  const openValuationformModal = () => {
    setValuationformOpen(true);
  }
  const closeValuationformModal = () => {
    setValuationformOpen(false);
  }

  const getPOA = (data) => {
    var permit = "";
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }

  const getPrice = (data, price) => {
    var permit = price.toLocaleString();
    if (!_.isEmpty(data?.POA)) {
      if (data.POA == "Y") {
        permit = "PRICE ON APPLICATION";
      }
    }
    return permit;
  }

  if(typeof window !== 'undefined') {
    localStorage.setItem('propertyPrice_cal', parseFloat(props.data.price));
    console.log("propertyPrice calc",localStorage.getItem('propertyPrice_cal'));
    localStorage.setItem('propertyPrice', CurrConvertPrice(props.data.price.toLocaleString()).slice(3));
    console.log("propertyPrice",localStorage.getItem('propertyPrice'));
  }

  useEffect(() => {
    if (props.data?.negotiator_id) {
      var people = _.find(peoples, function (o) { return o.reapit_code == props.data.negotiator_id; });
      if (!_.isEmpty(people?.certified_people)) {
        people.Name = people.certified_people.Name;
        people.Tile_Image.url = people.certified_people.Tile_Image.url;
        // people.URL = "";
        setOtherpeople(true)
      }
      setNegotiator(people);
    }

    if (props.data.images.length > 0) {
      setPropertyImg(props.data.images[0].url)
    }
  })

  var address = "";
  if(props?.data){
    if(props?.data?.address?.county){
      address += props?.data?.address.Address+", ";
    }
    if(props?.data?.address?.area){
      address += props?.data?.address.area+", ";
    }
    if(props?.data?.address?.town){
      address += props?.data?.address.town;
    }
  }

  var floor_area = props.data.floor_area ? " "+props.data.floor_area+" sq ft" : "" 
  var h1_addr = _.capitalize(props.data.title) + " in " +_.capitalize(address) + floor_area;

  var location_link = process.env.GATSBY_SITE_URL+location.pathname;

  var whatsapp_no = "97144232006"

	var whatsapp_link = `https://wa.me/${whatsapp_no}?text=Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	if(location.search.indexOf("utm_source") > 0){
		whatsapp_link = `https://wa.me/${whatsapp_no}?text=*G*%20Hello!%20I%20want%20to%20know%20more%20about%20${location_link}`
	}


  let processedImages = JSON.stringify({});
  if (negotiator?.imagetransforms?.Tile_Image_Transforms) {
    processedImages = negotiator.imagetransforms.Tile_Image_Transforms;
  }
  
  const getPhoneNumber = () => {
		return negotiator?.Phone || '+971 44 23 2006';
	};

	const number = getPhoneNumber();


  return (

    <React.Fragment>
      <div className="property-agent-info">
      {
          negotiator &&
          <>
           <div className="agent-contact-info d-flex">
              <Link href={`tel:${number}`} className="text-xxs btn btn-primary d-none d-lg-flex">
                <span><CallIconwhite className="icon-contact-info" /></span><span>Call</span>
              </Link>
              <Link href="javascript:void(0)" onClick={openAskformModal} className="text-xxs btn btn-primary">
                <span><EmailIconWhite className="icon-contact-info" /></span><span>Email</span>
              </Link>
              <Link href={whatsapp_link} className="text-xxs btn btn-primary" target="_blank">
                <span><WhatsAppIconWhite className="icon-contact-info" /></span><span className="text-whatsapp">WhatsApp</span>
              </Link>
           </div>
           {!(props.data.status == "Sold" || props.data.status == "Rented") &&
            <div className="d-none d-lg-flex book-view-btn w-100">
              <button className="btn btn-secondary" onClick={openBookViewformModal} >Book a Viewing</button>         
            </div>
          }
           <div className="agent-info">
              <div className="agent-pic">
                <ImageTransform imagesources={negotiator.Tile_Image.url} renderer="srcSet" imagename="peoples.Tile_Image.tile" attr={{ alt: negotiator.Name + ' - Dacha', class: '' }} imagetransformresult={processedImages} id={negotiator.id} />
              </div>
              <div className="agent-data">
                <h6 className="text-sm-bold"><Link href={`/about-dacha/our-team/${negotiator?.URL}/`}>{negotiator.Name}</Link></h6>
                <p className="text-xxs">{negotiator.Designation}</p>
              </div>
            </div>
            </>
            }
            <div className="share-info-wrap">
           <a className="share-info-icon text-xxs" href={"javascript:;"} onClick={openShareicons} onMouseLeave={closeShareicons}>
            <span className="share-icon"><ShareIcon /></span>
            <span>Share this Property</span>
            {Shareicons &&
              <div onMouseEnter={openShareicons} onMouseLeave={closeShareicons} className="property-share-social-icons">
                <FacebookShareButton onClick={() => trackerShare('FacebookShareButton')} url={shareurl} className="my-share-button facebook-share">
                  <FacebookIcon size={32} round={false} borderRadius={`10`} />
                </FacebookShareButton>
                <TwitterShareButton onClick={() => trackerShare('TwitterShareButton')} url={shareurl} className="my-share-button twitter-share">
                  <TwitterIcon size={32} round={false} borderRadius={`10`} />
                </TwitterShareButton>
                <LinkedinShareButton onClick={() => trackerShare('LinkedinShareButton')} url={shareurl} className="my-share-button linkedin-share">
                  <LinkedinIcon size={32} round={false} borderRadius={`10`} />
                </LinkedinShareButton>
                <WhatsappShareButton onClick={() => trackerShare('WhatsappShareButton')} url={shareurl} className="my-share-button whatsapp-share">
                  <WhatsappIcon size={32} round={false} borderRadius={`10`} />
                </WhatsappShareButton>
                {/*<EmailShareButton url='#' onClick={() => { openSendfrndformModal(); trackerShare('SendToFriend') }} className="my-share-button email-share">
                  <EmailIcon size={32} round={false} borderRadius={`10`} />
            </EmailShareButton>*/}
                <CopyToClipboard
                  options={{ debug: props.debug, message: "" }}
                  onCopy={() => setCopied(true)}
                  text={shareurl}
                >
                  <button className="" ref={target} href={"javascript:void(0)"} onClick={() => {
                    setCopied(true)
                    setTimeout(() => {
                      setCopied(false)
                    }
                      , 1000)
                  }}>
                    <i className="copy-icon"></i>
                  </button>
                </CopyToClipboard>
              </div>
            }
          </a>
          <Overlay target={target.current} show={copied} placement="right">
            {(props) => (
              <Tooltip id="overlay-example" {...props}>
                Copied!
              </Tooltip>
            )}
          </Overlay>

          {props.brochure && props.brochure.length != 0 ?
            <a href={props.brochure[0].srcUrl} target="_blank" className="">
              <i className="icon-print"></i>
              <span>Print Brochure</span>
            </a>
            : ''}
        </div>
      </div>
      <div className="sidebar-home-worth">
        <h6>What’s your home worth?</h6>
        <p className="text-xs">Curious about Dubai's neighborhoods? Explore our area guides, crafted by local experts just for you.</p>
        <div className="btn-wrapper">
						<button className="btn btn-secondary" onClick={openValuationformModal}>Book a Valuation</button>       
        </div>
      </div>
        {/* {(props.data.status == "Sold" || props.data.status == "Rented") &&
          <button className="btn btn-secondary tag-btn top-right" >{props.data.status}</button>
        } */}
        {/* <Button variant="link" className="btn-back" onClick={handleBack} onKeyPress={handleBack}>
          <i className="icon-back"></i><span> Back to Listings</span>
        </Button> */}
        {/* {address && 
          <div className="h1">{address}</div>          
        } */}
        {/* <h1 className="d-none">{h1_addr}</h1> */}
        {/* <div className="properties-icons area">
          {props.data && props.data?.address?.area &&
            <span className="about-location">
              <span className="location-info">
                {props.data && props.data.title ? props.data.title : ''}

              </span>
            </span>
          }
        </div> */}
        {/* <CurrConvertLayout parentCallback = {props.handleCurrConvert} outterClass="currency_select" />
        {props.data && props.data.price ?
          getPOA(props.data.extras) == "" ?
            <span className="product-price">
              {CurrConvertPrice(props.data.price.toLocaleString()).slice(3)} {(props.data.search_type == "lettings") ? 'per year' : null}
            </span>
            : <span className="product-price">{getPOA(props.data.extras)}</span>
          : ''

        } 

        <div className="properties-icons">*/}
          {/* {props.data && props.data.department == "residential" && props.data.bedroom ?
            <span className="bedroom-number">
              <i className="icon-bedroom">
              </i>
              <span class="sm-text">
                {props.data.bedroom}
              </span>
            </span> : ''
          }
          {props.data && props.data.department == "residential" && props.data.bathroom ?
            <span className="bathroom-number">
              <i className="icon-bath">
              </i>
              <span class="sm-text">
                {props.data.bathroom}
              </span>
            </span> : ''
          } */}
          {/* {props.data && props.data.reception ?
            <span className="bathroom-number">
              <i className="icon-sofa">
              </i>
              <span class="sm-text">
                {props.data.reception}
              </span>
            </span> : ''
          } */}
          {/* {props.data && props.data.floor_area ?
            <span className="bathroom-number">
              <i className="icon-space"></i>
              <span className="sm-text">
                {props.data.floor_area} ft<sup>2</sup>
              </span>
            </span> : ''
          } */}
        {/* </div>
        <div className="properties-icons">
          {props.data.crm_id &&
            <span className="bedroom-number">
              Ref.No : {props.data.crm_id}
            </span>
          }
        </div> */}

        {/* <div className="properties-icons">
          {props.data.extras.permit_number != "" &&
            <span className="bedroom-number">
              Permit No : {props.data?.extras?.permit_number}
            </span>
          }
        </div> */}

        {/* {!(props.data.status == "Sold" || props.data.status == "Rented") &&
          <div className="d-none d-xl-flex row-btn-group product-info-btn-group">
            <button className="btn btn-primary" onClick={openBookViewformModal} >Book a Viewing</button>
            <Link href={whatsapp_link} className="btn btn-outline btn-outline-dark btn-outline-whatsapp" target="_blank" >WhatsApp</Link>

          </div>
        } */}
       
      <ModalTiny show={modalValuationformOpen} onHide={closeValuationformModal} className="modal-form-wrapper form-valuation-wrapper">      
        <ModalTiny.Header>  
          <div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>        
          <ModalTiny.Title>
            <h4>Property Valuation</h4>
            <p className="text-sm">Receive a Professional Property Valuation in Minutes – Just Fill Out the Form</p> 
          </ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body>
          <ValuationFormPage />
        </ModalTiny.Body>
      </ModalTiny>
      <ModalTiny  show={modalSendfrndformOpen} onHide={closeSendfrndformModal} className="modal-form-wrapper form-sendFrnd-wrapper">
        <ModalTiny.Header>  
          <div class="modal-close-btn" onClick={closeValuationformModal}><CloseIcon /></div>       
          <ModalTiny.Title><h4>Share to a Friends</h4></ModalTiny.Title>
        </ModalTiny.Header>
        <ModalTiny.Body><SendForm
          isProductDetail={false}
          property_img={propertyImg}
          property_id={props.data && props.data.id ? props.data.id : ''}
          page_url={window.location.href}
          property_title={props.data && props.data.title ? props.data.title : ''}
          negotiator={props.data && props.data.address ? props.data.address.area : ''}

        /></ModalTiny.Body>
      </ModalTiny>

      <ModalTiny show={modalBookViewformOpen} onHide={closeBookViewformModal} className="modal-form-wrapper product-detail-page">
        <ModalTiny.Header>
				<div class="modal-close-btn" onClick={closeBookViewformModal}><CloseIcon /></div>
				<ModalTiny.Title>
					<h4>Book a Viewing</h4>
				</ModalTiny.Title>
				</ModalTiny.Header>
        <ModalTiny.Body>
          <Form
            isProductDetail={true}
            property_img={propertyImg}
            property_id={props.data && props.data.id ? props.data.id : ''}
            property_price={props.data && props.data.price ? `AED ${props.data.price.toLocaleString()}` : ''}
            page_url={window.location.href}
            property_title={props.data && props.data.title ? props.data.title : ''}
            negotiator_details={negotiator ? negotiator : null}
            negotiator={props.data && props.data.address ? props.data.address.county + ', ' + props.data.address.area : ''} /></ModalTiny.Body>

      </ModalTiny>
      {negotiator &&
        <ModalTiny show={modalAskformOpen} onHide={closeAskformModal} className="modal-form-wrapper people-details-form-modal">
          <ModalTiny.Header>
          <div class="modal-close-btn" onClick={closeAskformModal}><CloseIcon /></div>
            <ModalTiny.Title><h4>Contact {negotiator.Name}</h4></ModalTiny.Title>
          </ModalTiny.Header>
          <ModalTiny.Body className="event-section people-details-form-modal">
            <Form peopleName={negotiator.Name} contactusUrl = {shareurl} form_subject_contact = {"contact person"}/>
          </ModalTiny.Body>
        </ModalTiny>
      }

    </React.Fragment>
  )
}

export default ProductInfo